.cont {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.banner {
  display: flex;
  /* flex: 1 1 auto; */
  flex: 1;
  align-items: center;
}

.wrap {
  background-image: url(../../images/pattern-home.png);
  background-position: 70% 100%;
}

.uniIcons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4%;
  justify-content: space-evenly;
}

.box {
  display: flex;
  justify-content: center;
}

.fcea {
  display: flex;
  flex: 1;
  align-items: center;
}

.fing {
  display: flex;
  flex: 1;
}

.cursos {
  display: flex;
  flex: 1;
}

.benefitsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50%;
  justify-content: space-around;
  background-color: rgba(221, 221, 221, 0.7);
}

.benefits {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.left {
  margin-right: 10px;
}

.right {
  margin-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .benefits {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }

  .right {
    margin-left: 0;
  }
}

.left {
  display: flex;
  flex: 1 0;
  /* flex-direction: row; */
}

.right {
  display: flex;
  flex: 1 0;
}

.benefits .info {
  width: 100%;
  margin-bottom: 20px;
}

.imgs {
  display: flex;
  flex: 1 0;
  align-items: center;
}

.CardText {
  flex: 3;
  display: flex;
  align-items: center;
}

.CardContent {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 700px) {
  .imgs {
    flex: 2;
  }

  .CardText {
    flex: 2;
  }
}

@media only screen and (max-width: 550px) {
  .benefits .CardContent {
    flex-direction: column;
  }

  .benefits .MuiCardMedia-media {
    padding-top: 16px;
    width: 50%;
    margin: 0 auto;
  }
}

.title {
  padding-top: 2%;
}

.join {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 100%;
  justify-content: space-around;
  /* background-image: url(/src/svg/joinsec.svg); */
  background-image: url(../../svg/joinsec.svg);
  background-position: 96% 64%;
  background-size: 36% 280px;
  background-repeat: no-repeat;
  /* background: red; */
}

@media only screen and (max-width: 888px) {
  .join {
    background-image: none;
  }
}

.sec {
  padding-top: 2.6%;
  padding-bottom: 4%;
}

.listWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.list {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  padding-bottom: 120px;
}
/* .news {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  justify-content: space-around;
  background-image: url(../svg/News.svg);
  background-repeat: no-repeat; 
} 
 .novedades {
  display: flex;
  flex-direction: column;
}
.newscont {
  display: flex;
  flex-direction: row;
} 
 .newsimg {
  display: flex;
  flex: 1;
} 
.newstxt {
  display: flex;
  flex: 1;
}
.leftnews {
  display: flex;
  flex: 1;
  padding-left: 5%;
  padding-right: 2%;
}
.rightnews {
  display: flex;
  flex: 1;
  padding-left: 2%;
  padding-right: 5%;
} */


.CourseCardContainer{
    /*background: beige; */
    display: block;
    height: 232px;
    width: 360px;
    margin: 0px 12px;
    border-radius: 16px;
    border: 2px solid;
    padding: 4px; 
}

.CourseCardBack{
    /*background: aliceblue; */
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  border-radius: 12px;
  height: 100%;
}

.CourseCardBack div{
  border-radius: 12px;
  background-size: contain;
  padding: 28px 0;
  background-size: 60% 100%;
  /* width: -webkit-fill-available; */
  display: flex;
  align-items: flex-end;
  height: calc(100% - 28px*2);
  width: inherit;
}

.CourseCardContainer button{
  line-height: 36px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 0 20px;
  background: #fff;
  border: none;
  box-shadow: rgb(255, 255, 255, 0.16) 0px 3px 20px;
  font-size: 16px;
  cursor: pointer;
}

.CourseCardContainer img{
  position: absolute;
  top: 0;
  /* height: 20vh;
  width: auto;
  margin: 0 calc((100% - 20vh)/2); */
  height: 120px;
    width: auto;
    margin: 20px calc((100% - 120px)/2);
}

.CourseCardContainer button:hover{
  border: 1px solid #fff;
  color: #fff;
  /* line-height: 34px; */
}