.course-link {
  .paper:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:link {
    text-decoration: none;
  }
  &-text:hover {
    color: #014451;
  }
  &-text {
    display: inline;
  }
}
