.container {
  display: flex;
  flex-direction: column;
  background: white;
  padding-right: 10px;
  padding-left: 10px;
  align-items: center;
  /* justify-content: space-between; */
}

.left {
  display: flex;
  align-items: center;
  justify-content: left;
}

.hamburger {
  /* flex: 1; */
  padding-right: 20px;
}
.logo {
  display: flex;
  /* margin-top: 25px; */
  /* padding-top: 1px; */
  align-items: center;
  /* flex: 1; */
  /* margin-right: 50px; */
  /* background-color: red; */
}

.SearchBar {
  flex: 1;
  border-color: #014451;
  /* padding-right: 400px; */
  align-content: left;
}
.extras {
  flex: 3;
}
.buttons {
  display: flex;
  justify-content: right;
  flex-direction: row;
  flex: 3;
  align-items: stretch;
}

.button {
  text-decoration: none;
  display: block;
  color: #014451;
  text-align: center;
  align-items: center;
  margin: 0px;
  font-size: 20px;
}
/* .login {
  padding: 11px;
}
.signUp {
  padding: 11px;
}

.login:hover {
  background: #63c132;
  color: #cffcff;
}

.signUp:hover {
  background: #e26d5a;
  color: #d7bba8;
} */

/* .login {
} */
.signUp {
  padding-left: 5px;
}
