.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: 100vh;
}

/* .TopBar {
  padding-bottom: 0px;
} */

.TopBar {
  /* padding-right: 10px;
  padding-left: 10px; */
  background-color: white;
  flex-grow: 0;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto; */
  display: flex;
  flex-direction: row;
  /* padding-top: 52px; */
  /* margin: 0; */
}

/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.sticky + .nav {
  padding-top: 102;
} */
