.CustomCard{
  display: block;
  background: white;
  width: 100%;
  height: 168px;
  border-radius: 12px;
  padding: 12px 20px;
  box-shadow: 0px 4px 12px -6px rgba(0,0,0,0.1);
}
  
.CustomCard .imgs img{
  width: 100%;
  height: 100%; 
}

.CustomCard .imgs {
  width: 38%;
  height: 100%;
  /* background: aquamarine; */
  display: inline-block;
}
  
.CustomCard .CardText {
  display: block;
  width: 60%;
  height: 100%;
  float: right;
}
 
.CustomCard  h2{
  color: #373738;
  margin: 4px 0;
  font-size: 24px;
  font-weight: 500;
}
  
.CustomCard  p{  
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;

  /* line-height: 24px;
  font-size: 16px;
  color: #444449; */
}

@media only screen and (max-width: 390px) {
  .CustomCard{
    padding: 12px;
  }
}
@media only screen and (max-width: 512px) {
    .benefits .CardContent {
      flex-direction: column;
    }

    .CustomCard{
        height: initial;
    }
}