@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BebasNeue";
  src: local("BebasNeue"), url(./fonts/BebasNeue-Regular.ttf) format("truetype");
  font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a{
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

h1{
  margin: 0px auto 32px;
  color: rgb(1, 68, 81);
}

.button {
  width: 170px;
  margin: 10px;
  text-transform: none;
}

.button:hover {
  background-color: #00a6a3;
  color: #FFFFFD;
}


.button:disabled {
  background-color: #FFFFFD;
  border: 1px solid #dddddd;
}

.btnIPEPrimary:hover{
  background-color: #2F6670;
}
.btnIPEPrimary{
  background: #014451;
  padding: 8px 16px;
  color: white;
  width: auto;
}

.IPECard{
  border: 1px solid #eee;
  box-shadow: rgba(0, 0, 0, 0.086) 0px 3px 20px;
  border-radius: 8px;
}

.IPECard a:visited:not(.MuiButtonBase-root, button){
  color: #4a31f0; 
}