
h1{
  margin: 40px auto;
  text-align: center;
  font-size: 24px;
  text-transform: uppercase ;
}
.cont {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.banner {
  display: flex;
  /* flex: 1 1 auto; */
  flex: 1;
  align-items: center;
}

.wrap {
  background-image: url(../../images/pattern-home.png);
  background-position: 70% 100%;
}

.reset-link:hover {
  text-decoration: 'none';
}

.box {
  display: flex;
  justify-content: center;
}

.fcea {
  display: flex;
  flex: 1;
  align-items: center;
}

.fing {
  display: flex;
  flex: 1;
}

.cursos {
  display: flex;
  flex: 1;
}

.benefitsBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50%;
  justify-content: space-around;
  background-color: rgba(221, 221, 221, 0.7);
}

.benefits {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.left {
  margin-right: 10px;
}

.right {
  margin-left: 10px;
}

@media only screen and (max-width: 1200px) {
  .benefits {
    flex-direction: column;
  }
  .left {
    margin-right: 0;
  }

  .right {
    margin-left: 0;
  }
}

.left {
  display: flex;
  flex: 1 0;
  /* flex-direction: row; */
}

.right { 
  display: flex;
  flex: 1 0;
}

.benefits .info {
  width: 100%;
  margin-bottom: 20px;
}

.imgs {
  display: flex;
  flex: 1 0;
  align-items: center;
}

.CardText {
  flex: 3;
  display: flex;
  align-items: center;
}

.CardContent {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 700px) {
  .imgs {
    flex: 2;
  }

  .CardText {
    flex: 2;
  }
  .benefitsBox, .join{
    padding-left: 6%;
    padding-right: 6%;
  }
}

@media only screen and (max-width: 550px) {
  .benefits .CardContent {
    flex-direction: column;
  }

  .benefits .MuiCardMedia-media {
    padding-top: 16px;
    width: 50%;
    margin: 0 auto;
  }
}

.title {
  padding-top: 2%;
}

.join {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex-wrap: wrap; */
  padding-left: 10%;
  padding-right: 10%;
  /* padding-bottom: 100%; */
  justify-content: space-around;
  /* background-image: url(../../svg/joinsec.svg);
  background-position: 55% 134%;
  background-size: 60% 450px;
  background-repeat: no-repeat; */
}

.sec {
  padding-top: 2.6%;
  padding-bottom: 4%;
}

.listWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.list {
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  padding-bottom: 120px;
  padding-top: 12px;
  padding-bottom: 40px;
}
/* .news {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 5%;
  justify-content: space-around;
  background-image: url(../svg/News.svg);
  background-repeat: no-repeat; 
} 
 .novedades {
  display: flex;
  flex-direction: column;
}
.newscont {
  display: flex;
  flex-direction: row;
} 
 .newsimg {
  display: flex;
  flex: 1;
} 
.newstxt {
  display: flex;
  flex: 1;
}
.leftnews {
  display: flex;
  flex: 1;
  padding-left: 5%;
  padding-right: 2%;
}
.rightnews {
  display: flex;
  flex: 1;
  padding-left: 2%;
  padding-right: 5%;
} */

.CourseCardContainer{
  /*background: beige; */
  display: block;
  height: 232px;
  width: -webkit-fill-available;
  border-radius: 16px;
  border: 2px solid;
  padding: 4px;
  margin: 2vh;
}