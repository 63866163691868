.footer {
  width: 100%;
  display: flex;
  background-color: #014451;
  margin-top: auto;
}

.footWrap {
  display: flex;
  flex-direction: column;
  background-color: #014451;
  width: 100%;
}

.footInfo {
  display: flex;
  padding-bottom: 15px;
  padding-top: 15px;
  justify-content: center;
  flex-direction: column;
  background-image: url(../../svg/footer-top.svg);
  background-repeat: no-repeat;
}

.logo {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.socMedia {
  display: flex;
  justify-content: center;
}

.footer .info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
  flex-direction: column;
}

.CRight {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: white;
}
